import { h, createContext } from 'preact';
import { useState } from 'preact/hooks';

export const AppContext = createContext();

export const AppContextProvider = (props) => {
    const [state, setState] = useState(
        {
            observedSection: '',
            isFormModalActive: false,
            isPrivacyPolicyModalActive: false,
            formModalType: 'success',
            currentSlide: 1,
        });

    return (
        <AppContext.Provider value={[state, setState]}>
            {props.children}
        </AppContext.Provider>
    );
}