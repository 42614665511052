import { h } from 'preact';
import navigate from './navigate';
import {  useContext } from 'preact/hooks';
import { AppContext } from '../../../context/AppContext'

const MenuItem = ({name, section, setIsBurgerActive, href}) => {
    const [state, ] = useContext(AppContext);

    const handleClick = () =>{
        if(section){
            setIsBurgerActive(false);
            navigate(section);
        }
    }

    return (
        <li className={`menu__item`}>
            <a className={ `${section === state.observedSection
                && state.observedSection !==''
                ? 'menu__btn--active' : ''}
                ${href ? 'menu-link': 'menu__btn'}`}
                onClick={handleClick} href={href ? href : null}>
                {name}
            </a>
        </li>
    )
}

export default MenuItem