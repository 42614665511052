import { h } from 'preact';
import MenuItem from './MenuItem';
import Burger from './Burger';
import { useState } from 'preact/hooks';

const items = [
    {id: 1, name: 'O nas', section: 'about', href: null},
    {id: 2, name: 'Oferta', section: 'offer', href: null},
    {id: 3, name: 'Proces', section: 'process', href: null},
    {id: 4, name: 'Klienci', section: 'clients', href: null},
    {id: 5, name: 'Kontakt', section: 'contact', href: null},
    {id: 6, name: 'Pomoc zdalna', section: null, href: 'https://www.teamviewer.com/pl/'},
    {id: 7, name: 'Demo', section: null, href: 'https://www.comarch.pl/erp/comarch-optima/demo/'},
]

const Menu = () => {
    const [isBurgerActive, setIsBurgerActive] = useState(false);

    const handleScrollButton = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setIsBurgerActive(false);
    }

    return (
        <nav className="menu">
            <div className="menu__wrapper">
                <div onClick={handleScrollButton} className="menu__logo">
                    <img src='../../../assets/svg/logo/Comarch_ERP_Optima.svg' alt="Comarch ERP Optima" />
                </div>
                <Burger isActive={isBurgerActive} setIsActive={setIsBurgerActive} />
                <ul className={`menu__links
                    ${isBurgerActive
                        ? 'menu__links--open'
                        : ''}`}>
                    {items.map(({name, id, section, href}) =>{
                        return <MenuItem key={id} name={name} section={section}
                        isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} href={href} />
                    })}
                </ul>
            </div>
        </nav>
    )
}

export default Menu
