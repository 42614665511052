import { h } from 'preact';
import { Router } from 'preact-router';
import { AppContextProvider, } from '../context/AppContext';
import Menu from './smart-components/menu/Menu';
import Home from '../routes/home';

const App = () => {

	return(
		<AppContextProvider>
				<Menu />
				<Router>
					<Home path="/" />
				</Router>
		</AppContextProvider>
	)
}

export default App;