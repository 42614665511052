const navigate = (section) => {
  const bodyRect = document.body.getBoundingClientRect().top;

  const sectionElement = document.querySelector(`section.${section}`);
  const positionSection = sectionElement.getBoundingClientRect().top;
  const elemenSectionPosition = positionSection - bodyRect;
  const offsetSectionPosition = elemenSectionPosition - 90;

  window.scrollTo({ top: offsetSectionPosition, behavior: "smooth" });
};

export default navigate;
