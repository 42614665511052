import { h } from 'preact';

const Burger = ({isActive, setIsActive}) => {

    const handleClick = () => {
        setIsActive(!isActive);
    }

    return (
        <div onClick={handleClick}
            className={`burger
            ${isActive
            ? 'burger--active'
            : ''}`}>
            <div className='burger__lines' />
        </div>
    )
}

export default Burger